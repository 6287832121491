import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import './Hero.css';
import DexScreener from '../images/dexscreener.png';
import Image2 from '../images/pumpfun.webp';
import Image3 from '../images/tg.svg';
import Image4 from '../images/tw.svg';
import Fish1 from '../images/fish.gif';
import Fish2 from '../images/fish.gif';
import Fish3 from '../images/fish.gif';

const Hero = () => {
  const contractAddress = 'ENFcR4n3TTSzwDLxuCst3dUq8HvA1czhDB98cj8Ppump';

  const copyToClipboard = () => {
    navigator.clipboard.writeText(contractAddress);
    alert('Contract address copied to clipboard!');
  };

  const controls = useAnimation();

  useEffect(() => {
    const sequence = async () => {
      while (true) {
        await controls.start({
          x: [0, window.innerWidth - 100, 0], // Move from left to right and back to the start
          transition: { duration: 10, ease: "linear", repeat: Infinity }
        });
      }
    };
    sequence();
  }, [controls]);

  return (
<div className="hero">
  <div className="hero-content">
    <div className="additional-buttons">
      <div className="btn">
        <a href="https://dexscreener.com/solana/6nfbfmr6sfgdyby8zcbu645xk4ruyh1ojfzuqrtblvfh" className="image-btn">
          <img src={DexScreener} alt="DexScreener" className="button-image" />
        </a>
      </div>
      <div className="btn">
        <a href="https://pump.fun/ENFcR4n3TTSzwDLxuCst3dUq8HvA1czhDB98cj8Ppump" className="image-btn">
          <img src={Image2} alt="Image2" className="button-image" />
        </a>
      </div>
      <div className="btn">
        <a href="https://t.me/SaltyPortal" className="image-btn">
          <img src={Image3} alt="Image3" className="button-image" />
        </a>
      </div>
      <div className="btn">
        <a href="https://x.com/salty_on_sol" className="image-btn">
          <img src={Image4} alt="Image4" className="button-image" />
        </a>
      </div>
    </div>
    <div className="contract-address" onClick={copyToClipboard}>
      CA: {contractAddress}
    </div>
    <div className="salty-boy">One $Salty Boy!</div>

  </div>
  <motion.img src={Fish1} className="fish" alt="Fish 1" data-speed="2" animate={controls} />
  <motion.img src={Fish2} className="fish" alt="Fish 2" data-speed="4" animate={controls} />
  <motion.img src={Fish3} className="fish" alt="Fish 3" data-speed="6" animate={controls} />
</div>

  );
};

export default Hero;
